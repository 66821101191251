import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Navbar";
import { Route, Routes } from "react-router-dom";

import Home from "./component/Home";
import Services from "./component/Services";
import Categories from "./component/Categories/Categories";
import Categories1 from "./component/Categories";
import LatestReport from "./component/LatestReport";
import About from "./component/About";
import Contact from "./component/Contact";
import Career from "./component/Career";
import PageNotFound from "./component/PageNotFound";
import Footer from "./component/Footer";

function App() {
  return (
    <>
      <Navbar className="transition ease-in-out delay-150 " />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="home" element={<Home />} />
        <Route exact path="services/:id" element={<Services />} />
        <Route exact path="categories/:id" element={<Categories />} />
        <Route exact path="latestReports" element={<LatestReport />} />
        <Route exact path="about" element={<About />} />
        <Route exact path="contact" element={<Contact />} />
        <Route exact path="career" element={<Career />} />
        <Route path="" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
        <Route element={<PageNotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
