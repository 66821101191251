import { Fragment } from "react";
import {
  Disclosure,
  DisclosureButton,
  Menu,
  Transition,
  MenuButton,
  MenuItems,
  MenuItem,
  DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import Dropdown from "./component/Common/Dropdown";

const navigation = [
  { key: 1, name: "Home", href: "home", current: true },
  {
    key: 2,
    type: "dropdown",
    name: "Categories",
    options: [
      { key: 1, label: "Aerospace and Defence", href: "categories" },
      { key: 2, label: "Business and Finance", href: "categories" },
      { key: 3, label: "Electronic and Semiconductor", href: "categories" },
      { key: 4, label: "IT and Telecommunication", href: "categories" },
      { key: 5, label: "Medical Devices", href: "categories" },
      { key: 6, label: "Travel and Tourism", href: "categories" },
      { key: 7, label: "Agriculture", href: "categories" },
      { key: 8, label: "Chemical and Materials", href: "categories" },
      { key: 9, label: "Energy and Power", href: "categories" },
      { key: 10, label: "Machinery and Equipment", href: "categories" },
      { key: 11, label: "Pharmaceuticals and Healthcare", href: "categories" },
      { key: 12, label: "Automotive and Transportation", href: "categories" },
      { key: 13, label: "Consumer Goods and Retails", href: "categories" },
      { key: 14, label: "Food and Beverages", href: "categories" },
      { key: 15, label: "Manufacturing and Construction", href: "categories" },
      { key: 16, label: "Service and Software", href: "categories" },
    ],
    href: "categories",
    current: false,
  },
  { key: 4, name: "Latest Reports", href: "latestReports", current: false },
  {
    key: 2,
    type: "dropdown",
    name: "Services",
    options: [
      { key: 1, label: "Syndicated Research", href: "services" },
      { key: 2, label: "Custom Solution", href: "services" },
      { key: 3, label: "Marketing Solution", href: "services" },
    ],
    href: "Services",
    current: false,
  },
  { key: 5, name: "About Us", href: "about", current: false },
  { key: 6, name: "Contact Us", href: "contact", current: false },
  { key: 7, name: "Career", href: "career", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar(props) {
  let navigate = useNavigate();

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/`)}
                  className="flex-col text-white flex-shrink-0 items-center"
                >
                  <h1 className="text-3xl tracking-wider text-center text-bold">
                    SMS
                  </h1>
                  <h5 className="text-xs tracking-wider">Market Research</h5>
                </div>
                <div className="hidden sm:ml-20 sm:block content-center">
                  <div className="flex space-x-4 ">
                    {navigation.map((item) =>
                      item.type === "dropdown" ? (
                        <Dropdown
                          key={item.name}
                          data={{
                            title: item.name,
                            options: item?.options,
                          }}
                        />
                      ) : (
                        <button
                          key={item.name}
                          className="hover:scale-110 text-white font-semibold py-2 px-4 rounded"
                          onClick={() => navigate(`/${item.href}`)}
                        >
                          {item.name}
                        </button>
                      )
                    )}
                  </div>
                </div>
              </div>
              {/* Profile dropdown & Bell icon */}
              {/* <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  type="button"
                  className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <Menu as="div" className="relative ml-3">
                  <div>
                    <MenuButton className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </MenuButton>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <MenuItem>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Your Profile
                          </a>
                        )}
                      </MenuItem>
                      <MenuItem>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Settings
                          </a>
                        )}
                      </MenuItem>
                      <MenuItem>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Sign out
                          </a>
                        )}
                      </MenuItem>
                    </MenuItems>
                  </Transition>
                </Menu>
              </div> */}
            </div>
          </div>

          <DisclosurePanel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) =>
                item.type === "dropdown" ? (
                  <Dropdown
                    key={item.name}
                    data={{
                      title: item.name,
                      options: item?.options,
                    }}
                  />
                ) : (
                  <DisclosureButton
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </DisclosureButton>
                )
              )}
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}
