import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

const Services = () => {
  const { id } = useParams()
  console.log('paramID --->', id);
  const services = [
    { key: 1, label: "Syndicated Research", href: 'services' },
    { key: 2, label: "Custom Solution", href: 'services' },
    { key: 3, label: "Marketing Solution", href: 'services' }
  ]

  const [selectedServ, setSelectedServ] = useState();

  const servicesData = [
    {
      key: 1,
      title: "Syndicated Research",
      description: ["Syndicated market research reports are usually conducted by a market research firm on itsown. In most cases, the scope of the study is determined by the market research company itself, and the study is carried out using a proprietary research methodology.", "The results of these syndicated market research reports are non-exclusive, and multiple companies can access the same report. One of the key reasons why syndicated market research have remained a mainstay is because of their affordability. They usually cost less than a custom-made project, as the cost is shared by multiple-buyers.", "Our syndicated market research reports cover the world’s high growth markets. The sectors we cover includes, but is not limited to, automotive & transportation, electronics, semiconductors & ICT, retail & consumer products, automation and equipment, food & beverages, services & utilities, chemicals & materials, and energy, mining, oil & gas."]
    },
    {
      key: 2,
      title: "Custom Solutionh",
      description: ["Syndicated market research reports are usually conducted by a market research firm on itsown. In most cases, the scope of the study is determined by the market research company itself, and the study is carried out using a proprietary research methodology.", "The results of these syndicated market research reports are non-exclusive, and multiple companies can access the same report. One of the key reasons why syndicated market research have remained a mainstay is because of their affordability. They usually cost less than a custom-made project, as the cost is shared by multiple-buyers.", "Our syndicated market research reports cover the world’s high growth markets. The sectors we cover includes, but is not limited to, automotive & transportation, electronics, semiconductors & ICT, retail & consumer products, automation and equipment, food & beverages, services & utilities, chemicals & materials, and energy, mining, oil & gas."]
    },
    {
      key: 3,
      title: "Marketing Solution",
      description: ["Syndicated market research reports are usually conducted by a market research firm on itsown. In most cases, the scope of the study is determined by the market research company itself, and the study is carried out using a proprietary research methodology.", "The results of these syndicated market research reports are non-exclusive, and multiple companies can access the same report. One of the key reasons why syndicated market research have remained a mainstay is because of their affordability. They usually cost less than a custom-made project, as the cost is shared by multiple-buyers.", "Our syndicated market research reports cover the world’s high growth markets. The sectors we cover includes, but is not limited to, automotive & transportation, electronics, semiconductors & ICT, retail & consumer products, automation and equipment, food & beverages, services & utilities, chemicals & materials, and energy, mining, oil & gas."]
    }
  ]

  useEffect(() => {
    const selected = servicesData.filter(x => id == x.key);
    console.log('selected --->', selected);
    setSelectedServ(selected[0])
  }, [id])

  return (
    <>
      {selectedServ &&
        <div>
          <h1>{selectedServ.title}</h1>
          {selectedServ.description.map((e, i) => {
            return <p key={i}>{e}</p>
          })}
        </div>}
    </>
  );
};

export default Services;
