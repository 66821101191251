import React from "react";
import { Fragment } from "react";
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

const Dropdown = ({ data }) => {
  let navigate = useNavigate();
  return (
    <Menu as="div" className="relative inline-block text-left ">
      <div>
        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 text-base font-semibold text-white hover:scale-110 ">
          {data.title ? data.title : "Options"}
          <ChevronDownIcon
            className="-mr-1 h- w-5 text-gray-400"
            aria-hidden="true"
          />
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute  max-h-80 z-10 mt-2 w-80 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto">
          <div className="py-1">
            {data.options.map((item, i) => {
              return (
                <div className="flex" key={`${item.key}_${i}`}>
                  <MenuItem sx={{ maxHeight: "100px" }}>
                    <button
                      key={item.key}
                      className="hover:scale-105 text-gray-600 text-left font-semibold py-2 px-4 rounded"
                      onClick={() => navigate(`/${item.href}/${item.key}`)}
                    >
                      {item.label}
                    </button>
                  </MenuItem>
                </div>
              );
            })}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
