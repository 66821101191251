import React from "react";

const Career = () => {
  console.log('carrer');
  return (
    <div>
      <h1>Career</h1>
    </div>
  );
};

export default Career;
