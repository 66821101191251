import React from "react";

const LatestReport = () => {
  return (
    <div>
      <h1>Latest Report</h1>
    </div>
  );
};

export default LatestReport;
