import React from "react";
import dataReportImage from "../../assets/data_report.svg";

const Home = () => {
  return (
    <div
      className="border border-gray-100 shadow bg-no-repeat bg-[length:800px_400px]  bg-right bg-blue-50 content-center"
      style={{ backgroundImage: `url(${dataReportImage})`, height: "15em" }}
    >
      <div className="ml-40">
        <span className="grid grid-cols-1">
          <p className="text-base">SMARTER WAY TO DO</p>
          <p className="text-3xl">MARKET RESEARCH</p>
        </span>
        <p className="text-sm">
          Research Reports and Business Intelligence for Consistent Growth
        </p>
      </div>

      {/* <div class="flex w-96 justify-center">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input
          type="text"
          name="price"
          id="price"
          class="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="0.00"
        />
        <div class="absolute inset-y-0  flex items-center">
          <button className="rounded border-2 p-1">Search</button>
        </div>
      </div> */}

      <div className="flex justify-center justify-items-center content-end">
        <div className="">Input</div>
      </div>
    </div>
  );
};

export default Home;
