import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Categories = (props) => {
    const { id } = useParams()
    const cat = [
        { key: 1, label: "Aerospace and Defence", href: 'categories' },
        { key: 2, label: "Business and Finance", href: 'categories' },
        { key: 3, label: "Electronic and Semiconductor", href: 'categories' },
        { key: 4, label: "IT and Telecommunication", href: 'categories' },
        { key: 5, label: "Medical Devices", href: 'categories' },
        { key: 6, label: "Travel and Tourism", href: 'categories' },
        { key: 7, label: "Agriculture", href: 'categories' },
        { key: 8, label: "Chemical and Materials", href: 'categories' },
        { key: 9, label: "Energy and Power", href: 'categories' },
        { key: 10, label: "Machinery and Equipment", href: 'categories' },
        { key: 11, label: "Pharmaceuticals and Healthcare", href: 'categories' },
        { key: 12, label: "Automotive and Transportation", href: 'categories' },
        { key: 13, label: "Consumer Goods and Retails", href: 'categories' },
        { key: 14, label: "Food and Beverages", href: 'categories' },
        { key: 15, label: "Manufacturing and Construction", href: 'categories' },
        { key: 16, label: "Service and Software", href: 'categories' }
    ];

    const [selectedCat, setSelectedCat] = useState();

    useEffect(() => {
        const selected = cat.filter(x => id == x.key);
        setSelectedCat(selected[0])
    }, [id])

    return (
        <>
            {selectedCat && <div>
                Categories : {selectedCat.label}
            </div>}
        </>
    );
};

export default Categories;