import React from "react";

const PageNotFound = () => {
  return (
    <div class="flex h-96">
      <div class="m-auto">
        <h1 className="text-center text-4xl text-gray-400">404</h1>
        <h4 className="text-2xl text-gray-400">Not Found</h4>
      </div>
    </div>
  );
};

export default PageNotFound;
