import React from "react";
import aboutImg from "../../assets/about_us.png";

const About = () => {
  return (
    <section class="py-14 lg:py-24 relative">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative ">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-9">
          <div class="img-box">
            <img
              src={aboutImg}
              alt="About Us tailwind page"
              class="max-lg:mx-auto"
            />
          </div>
          <div class="lg:pl-[100px] flex items-center">
            <div class="data w-full">
              <h2 class="font-manrope font-bold text-4xl lg:text-5xl text-black mb-9 max-lg:text-center relative">
                About Us{" "}
              </h2>
              <p class="font-normal text-xl leading-8 text-gray-500 max-lg:text-center max-w-2xl mx-auto">
                Driven by a passion for seamless user experiences, we've
                meticulously curated pagedone to empower creators, designers,
                and developers alike. Our mission is to provide a comprehensive
                toolkit, enabling you to build intuitive, beautiful interfaces
                that resonate with users on every interaction.
              </p>
            </div>
          </div>
        </div>

        <div class="lg:pl-[100px] flex items-center">
          <div class="data w-full">
            <h2 class="font-manrope font-bold text-2xl lg:text-2xl text-black mb-9 mt-5 max-lg:text-center relative">
              Mission{" "}
            </h2>
            <hr />
            <p class="font-normal text-xl leading-8 text-gray-500 max-lg:text-center mt-5 max-w-2xl mx-auto">
              Driven by a passion for seamless user experiences, we've
              meticulously curated pagedone to empower creators, designers, and
              developers alike. Our mission is to provide a comprehensive
              toolkit, enabling you to build intuitive, beautiful interfaces
              that resonate with users on every interaction.
            </p>
          </div>
        </div>
        <div class="lg:pl-[100px] flex items-center">
          <div class="data w-full">
            <h2 class="font-manrope font-bold text-2xl lg:text-2xl text-black mb-9 mt-5 max-lg:text-center relative">
              Vision{" "}
            </h2>
            <hr />
            <p class="font-normal text-xl leading-8 text-gray-500 mt-5 max-lg:text-center max-w-2xl mx-auto">
              Driven by a passion for seamless user experiences, we've
              meticulously curated pagedone to empower creators, designers, and
              developers alike. Our mission is to provide a comprehensive
              toolkit, enabling you to build intuitive, beautiful interfaces
              that resonate with users on every interaction.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
